import { NewUser } from '../definitions/api';
import { backendUrl } from '../lib/backend';
import { handlePotentialErrorResponse } from './utils';
import { currentUserResponseSchema } from '../definitions/api.schemata';
import { CurrentUser } from '../definitions/definitions';

export async function getUserInfo(): Promise<CurrentUser> {
    return fetch(backendUrl('/api/user'), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then((response) => response.json())
        .then((json) => {
            const parsed = currentUserResponseSchema.safeParse(json);
            if (!parsed.success) {
                throw new Error(
                    `Invalid response format: ${parsed.error.toString()}`,
                );
            }
            return {
                type: parsed.data.type,
                ...parsed.data.user,
            };
        });
}

export function registerUser(user: NewUser): Promise<void> {
    return fetch(backendUrl('/api/users/'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
        credentials: 'include',
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then(() => {});
}

export function verifyUser(userId: string, token: string) {
    return fetch(backendUrl(`/api/users/${userId}/verify/${token}`), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then(() => {});
}

export function requestPasswordReset(email: string) {
    return fetch(backendUrl('/api/users/request_reset_password'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
        }),
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then(() => {});
}

export function resetPassword(token: string, userId: string, password: string) {
    return fetch(backendUrl(`/api/users/${userId}/reset_password/${token}`), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            new_password: password,
        }),
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then(() => {});
}
