/*
 Generated by typeshare 1.8.0
*/

export interface ApiVersionResponse {
    version: string;
}

export interface CaptchaParams {
    dark_mode?: boolean;
    width?: number;
    height?: number;
}

export type AttributeValue =
    | { type: 'Integer'; value: number }
    | { type: 'Float'; value: number }
    | { type: 'String'; value: string }
    | { type: 'Bool'; value: boolean }
    | {
          type: 'Color';
          value: {
              r: number;
              g: number;
              b: number;
              a: number;
          };
      }
    | { type: 'Timestamp'; value: string };

export interface Attribute {
    name: string;
    value?: AttributeValue;
}

export enum PropertyAlignment {
    Vertex = 'Vertex',
    Primitive = 'Primitive',
}

export enum PropertyType {
    Int64 = 'Int64',
    Float32 = 'Float32',
    Float64 = 'Float64',
    Bool = 'Bool',
    String = 'String',
    VecInt64 = 'VecInt64',
    VecFloat32 = 'VecFloat32',
    VecFloat64 = 'VecFloat64',
    VecBool = 'VecBool',
    Timestamp = 'Timestamp',
}

export interface Property {
    name: string;
    alignment: PropertyAlignment;
    data_type: PropertyType;
}

export enum GeometryType {
    PointSet = 'PointSet',
    MultiLine = 'MultiLine',
    Tin = 'Tin',
    Tetrahedral = 'Tetrahedral',
    Voxet = 'Voxet',
    SGrid = 'SGrid',
    Profile = 'Profile',
    ElevationGrid = 'ElevationGrid',
    Segy2D = 'Segy2D',
    Segy3D = 'Segy3D',
}

export enum ZAxisType {
    Spatial = 'Spatial',
    Temporal = 'Temporal',
    Any = 'Any',
}

export interface Geometry {
    id: string;
    dataset: string;
    name: string;
    geometry_type: GeometryType;
    zaxis_type: ZAxisType;
    attributes: Attribute[];
    properties: Property[];
    srs_proj4?: string;
    srs_epsg?: number;
}

export enum DataFormat {
    Gocad = 'Gocad',
    Ifc = 'Ifc',
    Isatis = 'Isatis',
    Netcdf = 'Netcdf',
    Obj = 'Obj',
    Resqml = 'Resqml',
    Sfs = 'Sfs',
    Stl = 'Stl',
    Shp = 'Shp',
    Gpkg = 'Gpkg',
    GeoH5 = 'GeoH5',
    Surpac = 'Surpac',
}

export interface Dataset {
    id: string;
    format: DataFormat;
    created_at: string;
    keep_data: boolean;
    main_file: string;
}

export interface DatasetWithGeometry {
    dataset: Dataset;
    geometries: Geometry[];
}

export enum ParserOptions {
    CoordinateOrientation = 'CoordinateOrientation',
    UpAxis = 'UpAxis',
}

export interface ParserInformation {
    name: string;
    supported_extensions: string[];
    options: ParserOptions[];
}

export enum WriterOptions {
    CoordinateOrientation = 'CoordinateOrientation',
    UpAxis = 'UpAxis',
    WriteNormals = 'WriteNormals',
}

export interface WriterInformation {
    name: string;
    options: WriterOptions[];
}

export interface MessagePayload {
    from_name: string;
    from_email: string;
    subject: string;
    message: string;
    captcha: string;
}

export interface Srs {
    name: string;
    code: string;
}

export interface Group {
    id: string;
    name: string;
    is_admin: boolean;
    max_quota?: number;
    max_file_size?: number;
    list_public: boolean;
    created_at: string;
    updated_at: string;
    data_retention_time?: number;
}

export interface User {
    id: string;
    login: string;
    display_name: string;
    group_data: Group;
    created_at: string;
    updated_at: string;
    stored_data_size: number;
    newsletter: boolean;
    group_membership_valid_until?: string;
}

export interface NewUser {
    login: string;
    display_name: string;
    password: string;
    news_letter: boolean;
    captcha: string;
    consent_agb: boolean;
}

export interface UpdateUser {
    display_name?: string;
    password?: string;
    group?: string;
    newsletter?: boolean;
    login?: string;
    group_membership_valid_until?: string | null;
}

export interface NewGroup {
    name: string;
    is_admin: boolean;
    max_quota?: number;
    max_file_size?: number;
    list_public: boolean;
    data_retention_time?: number;
}

export interface UpdateGroup {
    name?: string;
    is_admin?: boolean;
    max_quota?: number | null;
    max_file_size?: number | null;
    list_public?: boolean;
    data_retention_time?: number | null;
}

export interface JsonError {
    error_message: string;
}

export enum DownloadFormat {
    Gocad = 'gocad',
    VtkBin = 'vtkbin',
    GeoJson = 'geojson',
    Csv = 'csv',
    Gltf = 'gltf',
    Tiles3d = '3d-tiles',
    I3s = 'i3s',
    Ifc = 'ifc',
    Isatis = 'isatis',
    Kml = 'kml',
    Obj = 'obj',
    VtkAscii = 'vtkascii',
    Resqml = 'resqml',
    Sfs = 'sfs',
    Usd = 'usd',
    Shapefile = 'shapefile',
    Geopackage = 'gpkg',
    Stl = 'stl',
    Dxf = 'dxf',
}

export enum UpAxis {
    Y = 'Y',
    Z = 'Z',
}

export enum CoordinateOrientation {
    RightHanded = 'RightHanded',
    LeftHanded = 'LeftHanded',
}

export type CurrentUserResponse =
    | { type: 'LoggedIn'; user: User }
    | { type: 'DefaultUser'; user: User };
