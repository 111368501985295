import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export type Prompt = {
    id: string;
    question: string;
    resolve: (answer: boolean) => void;
};

type PromptState = {
    lastId: number;
    prompts: Prompt[];
    isRegistryMounted: boolean;
};

const usePromptStore = create<PromptState>()(
    subscribeWithSelector((_) => ({
        lastId: 0,
        prompts: [],
        isRegistryMounted: false,
    })),
);

export function addPrompt(prompt: Omit<Prompt, 'id'>) {
    const { lastId, prompts } = usePromptStore.getState();
    const id = (lastId + 1).toString();

    usePromptStore.setState({
        lastId: lastId + 1,
        prompts: [
            ...prompts,
            {
                id,
                ...prompt,
            },
        ],
    });
}

export function resolvePrompt(prompt: Prompt, answer: boolean) {
    const state = usePromptStore.getState();
    usePromptStore.setState({
        prompts: state.prompts.filter(({ id }) => id !== prompt.id),
    });

    prompt.resolve(answer);
}

export function setIsRegistryMounted(isRegistryMounted: boolean) {
    usePromptStore.setState({
        isRegistryMounted,
    });
}

export function getIsRegistryMounted(): boolean {
    return usePromptStore.getState().isRegistryMounted;
}

export const usePrompts = () => usePromptStore((state) => state.prompts);
