import { WrapRootElementBrowserArgs } from 'gatsby';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PopupNotificationContainer } from './components/organisms/popup-notification';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PromptRegistry } from './components/organisms/prompt-dialog';

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            {element}
            <PopupNotificationContainer />
            <PromptRegistry />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
