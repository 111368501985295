// Generated by ts-to-zod
import { z } from 'zod';
import {
    PropertyAlignment,
    PropertyType,
    GeometryType,
    ZAxisType,
    DataFormat,
    ParserOptions,
    WriterOptions,
    DownloadFormat,
    UpAxis,
    CoordinateOrientation,
} from './api';

export const apiVersionResponseSchema = z.object({
    version: z.string(),
});

export const captchaParamsSchema = z.object({
    dark_mode: z.boolean().optional(),
    width: z.number().optional(),
    height: z.number().optional(),
});

export const attributeValueSchema = z.union([
    z.object({
        type: z.literal('Integer'),
        value: z.number(),
    }),
    z.object({
        type: z.literal('Float'),
        value: z.number(),
    }),
    z.object({
        type: z.literal('String'),
        value: z.string(),
    }),
    z.object({
        type: z.literal('Bool'),
        value: z.boolean(),
    }),
    z.object({
        type: z.literal('Color'),
        value: z.object({
            r: z.number(),
            g: z.number(),
            b: z.number(),
            a: z.number(),
        }),
    }),
    z.object({
        type: z.literal('Timestamp'),
        value: z.string(),
    }),
]);

export const attributeSchema = z.object({
    name: z.string(),
    value: attributeValueSchema.optional(),
});

export const propertyAlignmentSchema = z.nativeEnum(PropertyAlignment);

export const propertyTypeSchema = z.nativeEnum(PropertyType);

export const propertySchema = z.object({
    name: z.string(),
    alignment: propertyAlignmentSchema,
    data_type: propertyTypeSchema,
});

export const geometryTypeSchema = z.nativeEnum(GeometryType);

export const zAxisTypeSchema = z.nativeEnum(ZAxisType);

export const geometrySchema = z.object({
    id: z.string(),
    dataset: z.string(),
    name: z.string(),
    geometry_type: geometryTypeSchema,
    zaxis_type: zAxisTypeSchema,
    attributes: z.array(attributeSchema),
    properties: z.array(propertySchema),
    srs_proj4: z.string().optional(),
    srs_epsg: z.number().optional(),
});

export const dataFormatSchema = z.nativeEnum(DataFormat);

export const datasetSchema = z.object({
    id: z.string(),
    format: dataFormatSchema,
    created_at: z.string(),
    keep_data: z.boolean(),
    main_file: z.string(),
});

export const datasetWithGeometrySchema = z.object({
    dataset: datasetSchema,
    geometries: z.array(geometrySchema),
});

export const parserOptionsSchema = z.nativeEnum(ParserOptions);

export const parserInformationSchema = z.object({
    name: z.string(),
    supported_extensions: z.array(z.string()),
    options: z.array(parserOptionsSchema),
});

export const writerOptionsSchema = z.nativeEnum(WriterOptions);

export const writerInformationSchema = z.object({
    name: z.string(),
    options: z.array(writerOptionsSchema),
});

export const messagePayloadSchema = z.object({
    from_name: z.string(),
    from_email: z.string(),
    subject: z.string(),
    message: z.string(),
    captcha: z.string(),
});

export const srsSchema = z.object({
    name: z.string(),
    code: z.string(),
});

export const groupSchema = z.object({
    id: z.string(),
    name: z.string(),
    is_admin: z.boolean(),
    max_quota: z.number().optional(),
    max_file_size: z.number().optional(),
    list_public: z.boolean(),
    created_at: z.string(),
    updated_at: z.string(),
    data_retention_time: z.number().optional(),
});

export const userSchema = z.object({
    id: z.string(),
    login: z.string(),
    display_name: z.string(),
    group_data: groupSchema,
    created_at: z.string(),
    updated_at: z.string(),
    stored_data_size: z.number(),
    newsletter: z.boolean(),
    group_membership_valid_until: z.string().optional(),
});

export const newUserSchema = z.object({
    login: z.string(),
    display_name: z.string(),
    password: z.string(),
    news_letter: z.boolean(),
    captcha: z.string(),
    consent_agb: z.boolean(),
});

export const updateUserSchema = z.object({
    display_name: z.string().optional(),
    password: z.string().optional(),
    group: z.string().optional(),
    newsletter: z.boolean().optional(),
    login: z.string().optional(),
    group_membership_valid_until: z.string().optional().nullable(),
});

export const newGroupSchema = z.object({
    name: z.string(),
    is_admin: z.boolean(),
    max_quota: z.number().optional(),
    max_file_size: z.number().optional(),
    list_public: z.boolean(),
    data_retention_time: z.number().optional(),
});

export const updateGroupSchema = z.object({
    name: z.string().optional(),
    is_admin: z.boolean().optional(),
    max_quota: z.number().optional().nullable(),
    max_file_size: z.number().optional().nullable(),
    list_public: z.boolean().optional(),
    data_retention_time: z.number().optional().nullable(),
});

export const jsonErrorSchema = z.object({
    error_message: z.string(),
});

export const downloadFormatSchema = z.nativeEnum(DownloadFormat);

export const upAxisSchema = z.nativeEnum(UpAxis);

export const coordinateOrientationSchema = z.nativeEnum(CoordinateOrientation);

export const currentUserResponseSchema = z.union([
    z.object({
        type: z.literal('LoggedIn'),
        user: userSchema,
    }),
    z.object({
        type: z.literal('DefaultUser'),
        user: userSchema,
    }),
]);
