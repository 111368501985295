import { CheckCircleIcon } from '@heroicons/react/24/outline';
import {
    ExclamationCircleIcon,
    InformationCircleIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import { NotificationType } from '../../molecules/notification-box';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';

export function PopupNotification(props: {
    type: NotificationType;
    title?: string;
    text: string;
    onClose: () => void;
}) {
    const { type, title, text, onClose } = props;

    return (
        <Transition
            show={true}
            appear={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-5000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div
                className={
                    'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'
                }
            >
                <div className="p-4">
                    <div className="flex items-start">
                        {type === 'success' && (
                            <div className="flex-shrink-0 text-giga-dark-green">
                                <CheckCircleIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                        {type === 'note' && (
                            <div className="flex-shrink-0 text-blue-400">
                                <InformationCircleIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                        {type === 'error' && (
                            <div className="flex-shrink-0 text-red-400">
                                <ExclamationCircleIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            {title && (
                                <p className="text-sm font-medium text-gray-900">
                                    {title}
                                </p>
                            )}
                            <p className="mt-1 text-sm text-gray-500">{text}</p>
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                            <button
                                type="button"
                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}
