import { useEffect } from 'react';
import { PromptDialog } from './prompt-dialog';
import { resolvePrompt, setIsRegistryMounted, usePrompts } from './state';

export function PromptRegistry() {
    const prompts = usePrompts();

    useEffect(() => {
        setIsRegistryMounted(true);
        return () => {
            setIsRegistryMounted(false);
        };
    }, []);

    if (prompts.length === 0) {
        return <></>;
    }

    const prompt = prompts[0];

    return (
        <PromptDialog
            key={prompt.id}
            question={prompt.question}
            onYes={() => {
                resolvePrompt(prompt, true);
            }}
            onNo={() => {
                resolvePrompt(prompt, false);
            }}
        />
    );
}
