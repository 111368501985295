import { PopupNotification } from './popup-notification';
import { useNotificationStore } from './notification-state';
import { shallow } from 'zustand/shallow';

export function PopupNotificationContainer() {
    const state = useNotificationStore(
        (state) => ({
            notifications: state.notifications,
            removeNotification: state.removeNotification,
        }),
        shallow,
    );

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                {state.notifications.map((notification) => (
                    <PopupNotification
                        key={notification.id}
                        type={notification.type}
                        title={notification.title}
                        text={notification.text}
                        onClose={() => {
                            state.removeNotification(notification.id);
                        }}
                    />
                ))}
            </div>
        </div>
    );
}
