import { addPrompt, getIsRegistryMounted } from './state';

export function promptUser(question: string): Promise<boolean> {
    if (!getIsRegistryMounted()) {
        throw new Error(
            'You need to render the PromptRegistry component somewhere in the application before you can use promptUser.',
        );
    }

    return new Promise<boolean>((resolve) => {
        addPrompt({
            question,
            resolve,
        });
    });
}
