import React, { PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import { cn } from '../../lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const buttonVariants = cva(
    'flex justify-center rounded-md px-3 py-1.5 text-sm leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border',
    {
        variants: {
            variant: {
                primary:
                    'font-semibold text-white hover:text-gray-100 bg-giga-main hover:bg-giga-light-main border-giga-main hover:border-giga-light-main',
                secondary:
                    'border-input bg-background hover:bg-accent hover:text-accent-foreground',
                disabled:
                    'font-semibold text-gray-400 bg-transparent border-gray-400',
            },
        },
        defaultVariants: {
            variant: 'primary',
        },
    },
);

export function Button(
    props: PropsWithChildren<{
        className?: string;
        variant?: VariantProps<typeof buttonVariants>['variant'];
        type?: 'submit' | 'button';
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
        to?: string;
        toExternal?: string;
        disabled?: boolean;
        download?: string;
        target?: string;
        rel?: string;
    }>,
) {
    const {
        className = '',
        variant,
        children,
        type = 'button',
        onClick,
        to,
        toExternal,
        disabled = false,
        target,
        download,
        rel,
    } = props;

    if (toExternal !== undefined) {
        return (
            <a
                className={cn(
                    buttonVariants({
                        variant: disabled ? 'disabled' : variant,
                    }),
                    'inline-block text-center',
                    className,
                )}
                href={toExternal}
                download={download}
                target={target}
                rel={rel}
            >
                {children}
            </a>
        );
    }

    if (to !== undefined) {
        return (
            <Link
                className={cn(
                    buttonVariants({
                        variant: disabled ? 'disabled' : variant,
                    }),
                    'inline-block text-center',
                    className,
                )}
                to={to}
                download={download}
                target={target}
                rel={rel}
            >
                {children}
            </Link>
        );
    }

    return (
        <button
            disabled={disabled}
            type={type}
            className={cn(
                buttonVariants({
                    variant: disabled ? 'disabled' : variant,
                }),
                className,
            )}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
