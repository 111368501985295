import { NotificationType } from '../../molecules/notification-box';
import { subscribeWithSelector } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

type Notification = {
    id: number;
    type: NotificationType;
    title?: string;
    text: string;
};

type NotificationState = {
    count: number;
    notifications: Notification[];
    addNotification: (
        type: NotificationType,
        text: string,
        title?: string,
    ) => void;
    removeNotification: (id: number) => void;
};

export const useNotificationStore = createWithEqualityFn<NotificationState>()(
    subscribeWithSelector((set) => ({
        count: 0,
        notifications: [],
        addNotification: (
            type: NotificationType,
            text: string,
            title?: string,
        ) =>
            set((state) => ({
                count: state.count + 1,
                notifications: [
                    ...state.notifications,
                    {
                        id: state.count,
                        type,
                        text,
                        title,
                    },
                ],
            })),
        removeNotification: (id: number) =>
            set((state) => ({
                notifications: state.notifications.filter(
                    (notification) => id !== notification.id,
                ),
            })),
    })),
);

export const useAddNotification = () => {
    return useNotificationStore((state) => state.addNotification);
};
