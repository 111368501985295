import {
    QueryClient,
    QueryKey,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import {
    getUserInfo,
    registerUser,
    requestPasswordReset,
    resetPassword,
    verifyUser,
} from '../api/user';
import { loginUser, logoutUser } from '../api/auth';
import { User } from '../definitions/api';

const DEFAULT_USER_CHECK_INTERVAL = 1000 * 60; // Check every minute, whether the user is still logged in

export const useCurrentUser = () =>
    useQuery({
        queryKey: ['currentUser'],
        queryFn: getUserInfo,
        staleTime: DEFAULT_USER_CHECK_INTERVAL,
        refetchInterval: (query) => {
            // We don't want to check again and again if the user is not logged in:
            if (query.state.data === undefined) {
                return false;
            }
            return DEFAULT_USER_CHECK_INTERVAL;
        },
    });

export const useCurrentLoggedInUser = () => {
    const { data, ...rest } = useCurrentUser();

    return {
        data: data?.type === 'LoggedIn' ? data : undefined,
        ...rest,
    };
};

export const useIsUserLoggedIn = () => {
    const { data, isPending } = useCurrentUser();
    return {
        isLoggedIn: data !== undefined && data.type === 'LoggedIn',
        isPending,
    };
};

export const useIsAdmin = () => {
    const { data, ...rest } = useCurrentUser();
    return {
        isAdmin: data !== undefined && data.group_data.is_admin,
        ...rest,
    };
};

export const useUserSpace = () => {
    const { data } = useCurrentUser();

    if (!data) {
        return {
            used: 0,
            quota: 0,
            maxFileSize: 0,
        };
    }

    return {
        used: data.stored_data_size,
        quota: data.group_data.max_quota || Infinity,
        maxFileSize: data.group_data.max_file_size || Infinity,
    };
};

export const useLogoutUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: logoutUser,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['currentUser'],
            });
        },
    });
};

export const useLoginUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (params: { username: string; password: string }) =>
            loginUser(params.username, params.password),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['currentUser'],
            });
        },
    });
};

export const useCurrentUserBasedQueryKey = (key: QueryKey) => {
    const { data } = useCurrentUser();
    return ['currentUser', data?.id, ...key];
};

export const getCurrentUserBasedQueryKey = (
    queryClient: QueryClient,
    key: QueryKey,
) => {
    const data = queryClient.getQueryData<User | null>(['currentUser']);

    return ['currentUser', data?.id, ...key];
};

export const useRegisterUser = () => {
    return useMutation({
        mutationFn: (params: {
            username: string;
            password: string;
            displayName: string;
            newsLetter: boolean;
            consentAgb: boolean;
            captcha: string;
        }) =>
            registerUser({
                login: params.username,
                password: params.password,
                display_name: params.displayName,
                news_letter: params.newsLetter,
                consent_agb: params.consentAgb,
                captcha: params.captcha,
            }),
    });
};

export const useVerifyUser = () => {
    return useMutation({
        mutationFn: (params: { token: string; userId: string }) =>
            verifyUser(params.userId, params.token),
    });
};

export const useRequestResetPassword = () => {
    return useMutation({
        mutationFn: (params: { email: string }) =>
            requestPasswordReset(params.email),
    });
};

export const useResetPassword = () => {
    return useMutation({
        mutationFn: (params: {
            password: string;
            token: string;
            userId: string;
        }) => resetPassword(params.token, params.userId, params.password),
    });
};
