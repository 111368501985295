import { Link } from 'gatsby';
import React, { Fragment } from 'react';
import { useIsAdmin, useIsUserLoggedIn } from '../queries/user';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
    Bars3Icon,
    XMarkIcon,
    UserCircleIcon,
} from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';

function Header() {
    const { isLoggedIn } = useIsUserLoggedIn();
    const { isAdmin } = useIsAdmin();

    const navigation = [
        { name: 'Convert', to: '/convert', current: false },
        { name: 'Datasets', to: '/datasets', current: false },
        { name: 'Pricing', to: '/pricing', current: false },
    ];

    if (isAdmin) {
        navigation.push({
            name: 'Administration',
            to: '/admin',
            current: false,
        });
    }

    return (
        <Disclosure as="nav" className="bg-white">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-900 hover:bg-giga-dark-green hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <Link to={'/'}>
                                        <StaticImage
                                            imgClassName="h-8 w-auto"
                                            src="../assets/giga_logo.png"
                                            alt="Logo of GiGa infosystems GmbH"
                                            height={32}
                                            placeholder={'none'}
                                        />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.to}
                                                className={clsx(
                                                    item.current
                                                        ? 'underline'
                                                        : '',
                                                    'rounded-md px-3 py-2 text-sm font-medium text-gray-900 hover:text-giga-dark-green',
                                                )}
                                                aria-current={
                                                    item.current
                                                        ? 'page'
                                                        : undefined
                                                }
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {!isLoggedIn && (
                                    <React.Fragment>
                                        <Link
                                            to={'/register'}
                                            className={
                                                'rounded-md px-3 py-2 text-sm font-medium text-gray-900 hover:text-giga-dark-green'
                                            }
                                        >
                                            Register
                                        </Link>
                                        <Link
                                            to={'/login'}
                                            className={
                                                'rounded-md px-3 py-2 text-sm font-medium text-gray-900 hover:text-giga-dark-green'
                                            }
                                        >
                                            Login
                                        </Link>
                                    </React.Fragment>
                                )}
                                {isLoggedIn && (
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="relative flex text-sm rounded-md text-gray-900 hover:text-giga-dark-green">
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">
                                                    User
                                                </span>
                                                <UserCircleIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            to={'/user'}
                                                            className={clsx(
                                                                active
                                                                    ? 'bg-gray-100'
                                                                    : '',
                                                                'block px-4 py-2 text-sm text-gray-700',
                                                            )}
                                                        >
                                                            Your Profile
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            to={'/logout'}
                                                            className={clsx(
                                                                active
                                                                    ? 'bg-gray-100'
                                                                    : '',
                                                                'block px-4 py-2 text-sm text-gray-700',
                                                            )}
                                                        >
                                                            Sign out
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                )}
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.to}
                                    className={clsx(
                                        item.current ? 'underline' : '',
                                        'text-gray-900 hover:bg-giga-dark-green hover:text-white block px-3 py-2 text-base font-medium',
                                    )}
                                    aria-current={
                                        item.current ? 'page' : undefined
                                    }
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

function Footer() {
    return (
        <footer className={'w-full mt-auto bg-giga-light-main text-white'}>
            <div className={'max-w-screen-lg mx-auto flex flex-col m-4'}>
                <nav className={'mx-auto flex flex-row gap-x-4'}>
                    <Link className={'text-sm'} to={'/imprint'}>
                        Imprint
                    </Link>
                    <Link className={'text-sm'} to={'/terms'}>
                        Terms of Service
                    </Link>
                    <Link className={'text-sm'} to={'/feedback'}>
                        Feedback
                    </Link>
                </nav>
                <div className={'text-center mt-4 text-xs'}>
                    © 2024 GiGa infosystems GmbH
                </div>
            </div>
        </footer>
    );
}

export default function Layout(props: { children: React.ReactNode }) {
    const { children } = props;

    return (
        <div className={'w-full min-h-screen flex flex-col'}>
            <Header />
            <main className={'w-full py-4'}>
                <div className={'max-w-screen-lg mx-auto'}>{children}</div>
            </main>
            <Footer />
        </div>
    );
}
