import { jsonErrorSchema } from '../definitions/api.schemata';

/**
 * Checks whether a response is ok (i.e. response code between 200 and 299). If
 * not an error is thrown, either with the error message sent by the server or
 * a default error message.
 * @param response
 */
export function handlePotentialErrorResponse(
    response: Response,
): Promise<Response> {
    if (response.ok) {
        return Promise.resolve(response);
    }

    return response
        .json()
        .catch(() => {
            // If the response cannot be parsed as json, we return a generic error
            throw new Error(mapStatusCodeToMessage(response.status));
        })
        .then((json) => {
            const parsedError = jsonErrorSchema.safeParse(json);
            if (parsedError.success) {
                throw new Error(parsedError.data.error_message);
            }
            throw new Error(`Unexpected server response (${response.status})`);
        });
}

function mapStatusCodeToMessage(code: number) {
    if (code > 299 && code < 400) {
        return `Unknown redirect error (${code})`;
    } else if (code > 399 && code < 500) {
        return `Unknown request error (${code})`;
    } else if (code > 499) {
        return `Unknown server error (${code})`;
    }
}
