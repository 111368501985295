import Dialog from '../../molecules/dialog';
import { Button } from '../../atoms/button';

export function PromptDialog(props: {
    question: string;
    onYes: () => void;
    onNo: () => void;
}) {
    const { question } = props;

    return (
        <Dialog isOpen={true} onClose={props.onNo} title={' '}>
            <div className={'flex flex-col gap-y-2'}>
                <p>{question}</p>
                <div className={'flex gap-x-2'}>
                    <Button onClick={props.onYes}>{'Yes'}</Button>
                    <Button onClick={props.onNo}>{'No'}</Button>
                </div>
            </div>
        </Dialog>
    );
}
