import { backendUrl } from '../lib/backend';
import { handlePotentialErrorResponse } from './utils';

export async function loginUser(
    username: string,
    password: string,
): Promise<void> {
    return fetch(backendUrl('/api/login'), {
        method: 'POST',
        body: JSON.stringify({
            username,
            password,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then(() => {});
}

export async function logoutUser(): Promise<void> {
    return fetch(backendUrl('/api/logout'), {
        method: 'GET',
        credentials: 'include',
    })
        .catch(() => {
            throw new Error('Request failed');
        })
        .then(handlePotentialErrorResponse)
        .then(() => {});
}
